import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import './footer.scss'
const Footer = () => {
    const data = useStaticQuery(graphql`
    {
      spoon: file(relativePath: { eq: "spoon.svg" }) {
                      relativePath
                      publicURL
                  }
      pot: file(relativePath: { eq: "pot.svg" }) {
                      relativePath
                      publicURL
                  }       
                          
      logo: file(relativePath: { eq: "logo.svg" }) {
                    relativePath
                    publicURL
                }  
      logomonema: file(relativePath: { eq: "logo-monema.svg" }) {
                    relativePath
                    publicURL
                }                         
                allMenuYaml {
                        edges {
                        node {
                            lang
                            menu{
                            nome
                            url
                            }
                        }
      
                        }
                        
                    }
    }
  `)


    const menu = data.allMenuYaml.edges[0].node.menu
    return (

        <footer>
            <div className="wrapper">

                <div className="footer-sx">
                    <img width="180" height="41" className="logo" src={data.logo.publicURL} alt="logo" />
                    <div className="box-inner">
                        <h1>Innovazione e creatività</h1>
                        <ul>
                            {
                                menu.map((item) => {
                                    return <li><a href={item.url}>{item.nome}</a></li>
                                })
                            }
                        </ul>

                    </div>
                </div>
                <div className="footer-dx">
                    <h1>powered by:</h1>
                    <a className="logofootermonema" href="https://monema.it"><img width="180" height="48" src={data.logomonema.publicURL} alt="logomonema"/></a>
                </div>
            </div>
        </footer>
    )
}


export default Footer