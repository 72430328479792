import * as React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import { globalHistory } from '@reach/router'
import ShareButtons from '../socialsharing/Socialsharing'

const { useState } = React;

// We can use inline-style


const Navbar = (props) => {

    const [toggleIcona, setToggleIcona] = useState(false)
    const variantsToggle = {
        open: { opacity: 1 },
        closed: { opacity: 0 },
    }
    const variantsNavbar = {
        open: { top: 0 },
        closed: { top: "-120px" },
    }
    const { location } = globalHistory
    const pathxx = location.pathname.toString().includes('schede')

    return (
        <StaticQuery
            query={graphql`
                query {
                image1: file(relativePath: { eq: "logo.svg" }) {
                    relativePath
                    publicURL
                }
                iconalogo: file(relativePath: { eq: "iconalogo.svg" }) {
                    relativePath
                    publicURL
                }
                allMenuYaml {
                        edges {
                        node {
                            lang
                            menu{
                            nome
                            url
                            }
                        }
      
                        }
                        
                    }
             
                    } 
            `}



            render={(data) => {
                const menu = data.allMenuYaml.edges[0].node.menu

                return (


                    pathxx ?
                        <>
                            <motion.button
                                initial={"open"}
                                animate={!toggleIcona ? "open" : "closed"}
                                variants={variantsToggle}
                                transition={{
                                    type: "spring",
                                    stiffness: 260,
                                    damping: 20,
                                    duration: 0.5,
                                }}
                                className="iconalogo" onClick={() => { setToggleIcona(true) }}>

                                <img src={data.iconalogo.publicURL} alt="icona logo" />

                            </motion.button>
                            <motion.div
                                initial={"closed"}
                                animate={toggleIcona ? "open" : "closed"}
                                variants={variantsNavbar}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    damping: 20,
                                    duration: 0.5,
                                }}

                                className="wrappernavbar" onMouseLeave={() => setToggleIcona(false)}>
                                <header>
                                    <nav>
                                        <Link to="/">
                                            <img width="238" height="75" className="logo" src={data.image1.publicURL} alt="logo" />
                                        </Link>
                                        <ul className="nav-links">
                                            {menu.map((item) => {
                                                if (item.nome === "Informazioni") {
                                                    return <li><Link to="/#informazioni">INFORMAZIONI</Link></li>
                                                } else {
                                                    return <li className={`${item.nome === 'Join us!' ? 'joinus' : ''}`}> <Link href={item.url}>{item.nome}</Link></li>
                                                }
                                            })}
                                        </ul>
                                    </nav>
                                </header>

                            </motion.div>
                        </> :
                        <div className="wrappernavbar">
              <ShareButtons url = {location.href} />
                            <header>
                                <nav>
                                    <Link to="/">
                                        <img width="238" height="75" loading="eager" className="logo" src={data.image1.publicURL} alt="logo" />
                                    </Link>
                                    <ul className="nav-links">
                                        {menu.map((item) => {
                                            return <li className={`${item.nome === 'Join us!' ? 'joinus' : ''}`}> <Link href={item.url}>{item.nome}</Link></li>
                                        })}
                                    </ul>
                                </nav>
                            </header>
                        </div>
                )
            }}
        />
    )
}
export default Navbar